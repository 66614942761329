
.component-flive .j-c-sb {
  margin-top:0.5% !important ;
}

.list-btn {
  margin-top: 1px !important;
  font-size: 8.5px !important;
}

.view-btn {
  font-size: 0.8rem !important;
  border: none;
  background-color: white !important;
  color: #9134d4;
  margin-top: 0%;
  text-align: left;
}

.hide-btn {
  background-color: white;
  color: white;
  border: none;
}

.announcement-row {
  margin: auto 0;
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-bottom: 15px !important;

  @media screen and (max-width: 460px) {
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-bottom: 15px !important;
  }

  .desc {
    height: 100px !important;
    // white-space: pre-wrap;
    color: #86838b;
    font-size: 11px;
    text-align: left;
    margin-top: 1px;

    // margin-top: 5px;
    // word-wrap: break-word;
    //   overflow: hidden;
    // height: 215px !important;
    // @media screen and (min-width: 768px) and (max-width: 990px) {
    //   height: 120px !important;
    // }
  }

  .desc-hw {
    height: 100px !important;
    white-space: pre-wrap;
    color: #86838b;
    font-size: 11px;
    text-align: left;
    margin-top: 5px;
    // word-wrap: break-word;
    //   overflow: hidden;
    // height: 215px !important;
    // @media screen and (min-width: 768px) and (max-width: 990px) {
    //   height: 120px !important;
    // }
  }
}
.ht-lmt-am {
  overflow: hidden;

  height: 100px !important;
  // line-height: 0.5;

  @media screen and (min-width: 768px) and (max-width: 990px) {
    height: 120px !important;
  }
  @media screen and (min-width: 345px) and (max-width: 990px) {
    height: 20% !important;
  }
}

.ht-lmt-all {
  overflow: hidden;
  // height: 250px !important;
  @media screen and (min-width: 768px) and (max-width: 990px) {
    height: 120px !important;
  }
}

.announcement-row:last-of-type {
  .hr-line {
    display: none;
  }

  @media screen and (max-width: 460px) {
    margin-bottom: unset !important;
  }
}
