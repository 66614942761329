$mediaMaxWidth: 1260px;
$mediaBp1Width: 960px;
$mediaMinWidth: 480px;

.cmf-report-container{
    margin: 10px;
    margin-right: 4%;
    @media screen and (max-width: 460px) {
        // margin: -41px;
      }

    
    .bg{
        overflow-y: hidden;
    }
    
    .bannerImageContainer{
        display: block;
        margin: auto;
        border-radius: 32px;
        width: 70%;
        margin-left: 18%;
        // background: url("../../assets/Report/upper.png");
        background-repeat: no-repeat, no-repeat;
        // background-size: 100% 100%;
        height: 150px;
        object-fit: contain;
    
        @media screen and (max-width: 460px) {
            border-radius: 0px;
            // background: url("../../assets/Report/bg.png");
            margin-left: -3%;
            width: 112%;
        }

        .bannerImage{
            width: 100%;
            @media screen and (min-width: 460px) {
                border-radius: 24px;
            }
        }
    }
    
    
    .bannerImage1Container{
        margin-top: 20%;
        display: block;
        border-radius: 19px;
        width: 70%;
        margin-left: 18%;
        // background: url("../../assets/Report/Bannerlast1.png");
        background-repeat: no-repeat, no-repeat;
        // background-size: 100% 100%;
        height: 150px;
        object-fit: contain;

        @media screen and (max-width: 460px) {
            border-radius: 12px;
        // background: url("../../assets/Report/Bannerlastmob.png");
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: unset;
            // margin-left: 3%;
            // margin-right: 3%;
            width: 100%;
            background-repeat: no-repeat;
            height: 235px;
            margin-top: 68%;
        }

        .bannerImage1{
            width: 100%;
            border-radius: 12px;
        }
    }
    
    .heading1{
        margin-left: 8%;
        left: 25.26%;
        right: 64.64%;
        top: 19.55%;
        bottom: 79.32%;
        font-family: Rubik;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 28px;
        text-transform: capitalize;
        color: #313131;
    }
    
    .heading2{
        margin-left: 2%;
        left: 25.26%;
        right: 64.64%;
        top: 19.55%;
        bottom: 79.32%;
        font-family: Rubik;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 28px;
        text-transform: capitalize;
        color: #313131;
    }
    
    .heading3{
        margin-left: 1%;
        left: 25.26%;
        right: 64.64%;
        top: 19.55%;
        bottom: 79.32%;
        font-family: Rubik;
        font-style: normal;
        font-weight: 550;
        font-size: 18px;
        line-height: 28px;
        text-transform: capitalize;
        color: #313131;
        @media screen and (max-width: 460px) {
            line-break: anywhere;
        letter-spacing: 0px;
    
    
          }
    }
    
    .hrline {
        margin: 0% -8% 1% 3%;
    }
    
    .hrline1{
        margin:0% 12% 1% 19%;
        @media screen and (max-width: 460px) {
            margin: 1% 4% 1% 8%;
          }
        }
    
    
    hr{
        @media screen and (max-width: 460px) {
            border-top: 4px solid rgba(0,0,0,.1) !important;
    
          }
    }
    
    .reportImage{
        width: 9%;
        margin-left: 3%;
        margin-top: 2%;
        @media screen and (max-width: 460px) {
            width: 80px;
            object-fit: contain;
            height: 33px;
          }
    }
    
    tbody{
        text-align: center;
    }
    
    thead{
        text-align: center;
        background-color: #9134D8;
        color: white;
    }
    
    th{
        font-family: Rubik;
        font-style: normal;
        font-weight: 400;
    }
    
    .bannercontainer{
        position: relative;
        height: 251px;
        left: 0px;
        top: 208px; 
        width: 105%;
        background: #F4EBFB;
        display: grid;
        @media screen and (max-width: 460px) {
            height: 40em;
            margin-top: 8%;
        }
    }
    
    .icon1{
        width: 4%;
        float: left;
        margin-right: 3%;
        border-radius: 5px;
        @media screen and (max-width: 460px) {
            width: 19%;
           }
    }
    
    
    .side{
        /* margin-left: 37%; */
        width: 65%;
        @media screen and (max-width: 460px) {
            display: none;
         
           }
    }
    
    .side1{
        margin-left: 69%;
        width: 65%;
        @media screen and (max-width: 460px) {
            display: none;
           }
    }
    
    td{
        border-color: none !important;
    }
    
    
    .border{
        border: 1px solid black;
        border-radius: 5px !important;
    }
    
    .upper{
        margin-top: 8%;
        @media screen and (max-width: 460px) {
            margin-top: 28%;
          }
    }
    
    .tableHeading{
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        text-transform: capitalize;
        color: #313131;

        @media screen and (max-width: 460px) {
            font-size: 14px;
        }
    }

    .tableSubHeading{
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: #313131;
        @media screen and (max-width: 460px) {
            font-size: 12px;
        }
    }
    
    .tableData{
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 28px;
        color: #313131;
    }
    
    .data1{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 120% */
    
    text-align: center;
    text-transform: capitalize;
    
    color: #313131;
    }
    
    .tablecontainer{
    padding: 3%;
    width: 112%;
    @media screen and (max-width: 460px) {
        overflow-wrap: anywhere;
    
      }
    
    }
    
    .paratext{
         float: left;
        width: 40%;
        white-space: nowrap;
        font-size: 14px;
         @media screen and (max-width: 460px) {
       width: 63%;
       white-space: normal ;
          }
    }
    
    .paracontainer{
        margin-left: 18%;
        margin-right: 7%;
        @media screen and (max-width: 460px) {
            margin-left: 4%;
    
               }
    }
    
    .paracontain{
        margin-left:18% ;
         padding-top:2%;
         font-size:21px;
         @media screen and (max-width: 460px) {
            margin-left: 5% !important;
         padding-top:5% !important;
    
    
               }
    }
    
    
    
    .row{
        @media screen and (max-width: 460px) {
            display: flex;
            flex-wrap: wrap;
            margin-right: -46px;
            margin-left: -65px;
               }
    
    }
    
    .uppertext{
        padding-left:7%;
        padding-top:5%;
        margin-left:12%;
        @media screen and (max-width: 460px) {
            margin-left:3% !important;
        }
    }
    .footer-container{
        display: flex;
        margin-top: 40px;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: $mediaMinWidth) {
            flex-direction: column-reverse;
        }
        .footer-logo{
            height: 30px;
            margin:3% 0% 1% 3%;
            @media screen and (max-width: 460px) {
                height: 30px;             
               }
        }
    
        .footertext{
            margin-left:68%;
            margin-top:1%;
            @media screen and (max-width: 460px) {
                margin-left:0%;
            margin-top:0%;
            padding-right: 2%;
            
              }
        }
        
        .footerpara{
            /* font-family: Inter; */
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: right;
        color: #9134D8;
        margin-top: 11%;
        
        }
        
        .footerpara1{
            /* font-family: Inter; */
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: right;
        color:  #8B8B8B;
        ;
        }
    }
      
}

body{
    font-family: Rubik;
font-style: normal;
overflow-x: hidden;
background-color: white !important;
}