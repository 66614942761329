.customLabelStyle {
  background: transparent !important;
  // font-size: 0.7em !important;
  text-align: left !important;
  margin-top: 0.5em !important;
  border: transparent !important;
  color: white !important;
  // font-size: 13px !important;
  font-weight: 500 !important;
  // opacity: 0.7;
  line-height: 16px;
  font-size: 0.6em !important;
  text-align: left !important;
  margin-top: 0.5em !important;
  border: transparent !important;
  color: #524e59;
  width: 160%;
  margin-left: 5%;
  @media screen and (max-width: 767px) {
    border-radius: 4px !important;
    width: 100%;
    margin-left: 60%;
    background: transparent !important;
    text-align: left !important;
    margin-top: 0.5em !important;
    border: transparent !important;
    color: white !important;
    font-weight: 500 !important;
    opacity: 0.7;
    line-height: 16px;
    font-size: 0.6em !important;
    text-align: left !important;
    margin-top: 0.5em !important;
    border: transparent !important;
    color: #524e59;
  }

  @media screen and (max-width: 630px) {
    border-radius: 4px !important;
    width: 50%;
    // margin-left: 60%;
    background: transparent !important;
    text-align: left !important;
    margin-top: 0.5em !important;
    border: transparent !important;
    color: white !important;
    font-weight: 500 !important;
    opacity: 0.7;
    line-height: 16px;
    font-size: 0.6em !important;
    text-align: left !important;
    margin-top: 0.5em !important;
    border: transparent !important;
    color: #524e59;
  }
}

.hide {
  display: none;
}

.ptmLabelClass {
  background: rgb(242, 189, 29);
  height: 22px;
  width: 46px;
  border-radius: 0px 5px 0px 5px;
  padding: 4px;
  font-weight: 600;
  font-size: 12px;
  margin-left: auto;
}

.container {
  max-width: 100%;
  padding-left: 80px;
  padding-right: 80px;
}

.container {
  @media screen and (max-width: 767px) {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    height: unset !important;
  }
}

// .nav {
//   width: 80%;
// }

#question-icon {
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 3px;
  right: 20px;
  z-index: 9999;
  cursor: pointer;
}
.component-flive.main-wrapper .center-column {
  @media screen and (max-width: 767px) {
    height: unset !important;
  }
}
.component-flive {
  .cardTitleSB {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .d-flex {
    display: flex;
  }

  .j-c-sb {
    justify-content: space-between;
  }

  .annoucement-date {
    font-size: 9px !important;
  }

  &.main-wrapper {
    //.navbar-container {
    //@media screen and (max-width: 767px) {
    //  display: none;
    //}
    //}

    .wrapperSecion {
      padding: 96px 0 0;
      background-color: #ececec;
      font-family: Rubik;
      &.padding-top-0 {
        padding: 0px;
      }
      @media screen and (max-width: 767px) {
        padding: 82px 0 0 0;
        &.padding-top-0 {
          padding: 0px;
        }
      }

      .left-column {
        height: 85vh;
        overflow-y: auto;
        padding-left: 8px;
        padding-right: 8px;
        @media screen and (max-width: 460px) {
          height: 100%;
        }
      }

      .payments-item {
        img.mr-3 {
          width: 47px;
        }

        .media {
          align-items: center;

          img {
            margin: 0 40px 0 11px !important;
          }
        }
      }

      .order-list {
        .btn {
          border-radius: 2px;
          min-width: 121px;
          font-size: 13px;
          padding: 7px 0 7px 0;
        }

        img.mr-4 {
          width: 70px;
          height: 70px;
          object-fit: cover;
          box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
          border-radius: 2px;
        }
      }

      .osahan-account-page-left {
        .nav-link {
          padding: 18px 20px;
          border: none;
          font-weight: 600;
          color: #535665;

          i {
            width: 28px;
            height: 28px;
            background: #535665;
            display: inline-block;
            text-align: center;
            line-height: 29px;
            font-size: 15px;
            border-radius: 50px;
            margin: 0 7px 0 0px;
            color: #fff;
          }
        }

        .nav-link.active {
          background: #f3f7f8;
          color: #282c3f !important;

          i {
            background: #282c3f !important;
          }
        }
      }

      .osahan-user-media {
        img {
          width: 90px;
        }
      }

      .card {
        border: none;
        //background-color: transparent !important;
        padding: 0 !important;
        margin-bottom: 0 !important;

        offer-card {
          h5.card-title {
            border: 2px dotted #000;
          }
        }
      }

      .card.offer-card {
        h5 {
          border: 1px dotted #daceb7;
          display: inline-table;
          color: #17a2b8;
          margin: 0 0 19px 0;
          font-size: 15px;
          padding: 6px 10px 6px 6px;
          border-radius: 2px;
          background: #fffae6;
          position: relative;

          img {
            height: 22px;
            object-fit: cover;
            width: 22px;
            margin: 0 8px 0 0;
            border-radius: 2px;
          }

          &:after {
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-bottom: 4px solid #daceb7;
            content: "";
            left: 30px;
            position: absolute;
            bottom: 0;
          }

          &:before {
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 4px solid #daceb7;
            content: "";
            left: 30px;
            position: absolute;
            top: 0;
          }
        }
      }

      .reviews-members {
        .media {
          .mr-3 {
            width: 56px;
            height: 56px;
            object-fit: cover;
          }
        }
      }

      .osahan-cart-item {
        p.text-gray.float-right {
          margin: 3px 0 0 0;
          font-size: 12px;
        }

        .food-item {
          vertical-align: bottom;
        }
      }

      .shadow-sm {
        box-shadow: 1px 1px 1px 0px rgba(44, 11, 74, 0.1) !important;
      }

      .rounded-pill {
        border-radius: 50rem !important;
      }

      a {
        &:hover {
          text-decoration: none;
        }
      }

      .updatedate {
        display: block;
        border-right: 1px solid rgba(129, 137, 156, 0.2);
        padding-right: 15px;
        // width: 40%;
        @media screen and (max-width: 460px) {
          padding-top: 14px;
          left: 15px;
          flex-basis: 48%;
        }

        .dateTitle {
          font-size: 20px;
          font-family: Rubik;
          width: 100%;
          font-weight: 500;
          color: #000;
        }

        .timeTitle {
          color: #524e59;
          font-size: 12px;
          font-family: Rubik;
          font-weight: 400;
        }
      }

      .media-body {
        padding-left: 20px;

        .ptm-tag {
          background-color: #f2bd1d;
          color: black;
          width: auto;
          padding: 1px 15px;
          border-radius: 0px 3px;
          font-weight: 500;
          position: absolute;
          top: -1px;
          left: 0;
        }

        .upcoming {
          flex-direction: row;
          display: flex;
          align-items: center;
          justify-content: space-between;
          @media screen and (max-width: 460px) {
            flex-direction: column;
            align-items: flex-start;
          }
        }

        p {
          color: #524e59;
          font-size: 12px;
          font-weight: 400;
          text-align: left;
        }

        .announcement-date {
          font-size: 9px;
          color: #524e59;
          font-size: 12px;
          font-weight: 400;
          text-align: left;
        }

        h4 {
          color: #524e59;
          font-size: 14px;
          font-weight: 500;

          &.announcement-heading {
            font-weight: bold;
          }
        }

        .anchor-time {
          color: #524e59;
          opacity: 0.5;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
        }

        &.mediaBody {
          padding-left: 8px;
          align-self: center;
        }
      }

      .column-reverse {
        @media screen and (max-width: 767px) {
          flex-direction: column-reverse;
        }
      }
    }

    .osahan-user-media-body {
      width: 100%;
      //p {
      //  color: #524e59;
      //  font-size: 16px;
      //  font-weight: 500;
      //  opacity: 0.7;
      //  margin: 0;
      //}
    }

    .cardTitle-row {
      display: block;
      padding-bottom: 17px;

      &.flex-mobile-view {
        @media screen and (max-width: 767px) {
          display: flex;
          align-items: flex-end;
          padding-bottom: 8px;
        }
      }

      &.next-text-row {
        @media screen and (max-width: 767px) {
          padding-bottom: 8px;
        }
      }

      .cardTitle {
        font-weight: 500;
        font-size: 16px;
        text-align: left;
        color: #000;
        text-transform: uppercase;

        .headTecherIcon {
          width: 16px;
          vertical-align: revert;
          margin-right: 8px;
        }

        a {
          font-size: 13px;
          font-weight: 500;
          color: #524e59;
          color: rgba(176, 45, 243, 1);
          text-decoration: none;
          text-transform: capitalize;
          padding-left: 15px;
        }
      }
    }

    .media-left {
      border-radius: 100px;
      overflow: hidden;
      background: #000;
      width: 40px;
      height: 40px;

      a {
        display: block;

        .photo-profile {
          width: 100%;
        }
      }
    }

    .session-desc {
      font-size: 12px;
      font-weight: 400;
      color: #524e59;
      opacity: 0.7;
      line-height: 16px;
      position: relative;
    }

    .post-body {
      .post-text {
        padding-top: 9px;
        font-size: 12px;
        font-weight: 400;
        color: #524e59;
        opacity: 0.7;
        line-height: 16px;
        text-align: left;
        position: relative;
      }

      &.postBodyRight {
        position: relative;
        //border-bottom: 2px solid rgba(129, 137, 156, 0.2);
      }
    }

    .osahan-account-page-right {
      position: relative;
      z-index: 0;
      .left-verticle-redio {
        display: block;
        width: 16px;
        float: left;
        height: 100%;
        /* position: absolute; */
        border-left: 1px solid #00c1ff;
        left: 0;
        top: 0;
      }

      .tabContent {
        display: flex;
        position: relative;
        margin-left: 10px;
        margin-right: 10px;

        &:before {
          content: "";
          width: 2px;
          height: 100%;
          position: absolute;
          left: 7.3px;
          top: 0;
          //background: rgba(43, 165, 204, .2);
          background: rgb(213, 237, 245);
          z-index: 0;
        }

        &:after {
          content: "";
          width: 10px;
          height: 10px;
          position: absolute;
          left: 3px;
          border: 1px solid #fff;
          top: 40px;
          border-radius: 100px;
          background: #d5edf5;
          z-index: 0;
        }

        .tab-pane {
          width: 99%;

          &.tabPane {
            padding: 5px 5px 5px 18px;

            input[type="radio"] {
              ~ label {
                background: white;
              }
            }

            &.upcoming {
              input[type="radio"] {
                pointer-events: none;

                ~ label {
                  pointer-events: none;
                }
              }
            }

            :hover {
              background: #cfcfcf;
              border-color: transparent;
            }
          }
        }
      }
    }

    .labelTo {
      flex: 1;
      padding: 0.5rem;
    }

    input[type="radio"] {
      display: none;

      &:not(:disabled) ~ label {
        cursor: pointer;
      }

      &:disabled ~ label {
        color: hsla(150, 5%, 75%, 1);
        border-color: hsla(150, 5%, 75%, 1);
        box-shadow: none;
        cursor: not-allowed;
      }
    }

    label {
      height: auto;
      display: block;
      background: white;
      border: 1px solid rgba(129, 137, 156, 0.2);
      border-radius: 6px;
      padding: 0;
      margin-bottom: 0;
      //margin: 1rem;
      text-align: center;
      //box-shadow: 0px 3px 10px -2px hsla(150, 5%, 65%, 0.5);
      position: relative;
    }

    input[type="radio"] + label {
      background: rgba(130, 138, 157, 0.2);
      color: #524e59;

      &::before {
        display: none;
        border: 2px solid rgba(43, 165, 204, 0.4);
        content: "";
        position: absolute;
        top: 30.8px;
        left: -10.6px;
        transform: translateX(-50%);
        height: 16px;
        width: 16px;
        line-height: 50px;
        z-index: 1;
        text-align: center;
        border-radius: 50%;
        box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.25);
      }

      &::after {
        display: none;
        color: #f00;
        border: 2px solid #00c1ff;
        content: "";
        z-index: 1;
        background-color: #00c1ff;
        font-size: 24px;
        position: absolute;
        top: 35.5px;
        left: -10.3px;
        transform: translateX(-50%);
        height: 7px;
        width: 7px;
        line-height: 50px;
        text-align: center;
        border-radius: 50%;
        box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.25);
      }
    }

    input[type="radio"]#control_05:checked + label {
      background: red;
      border-color: red;
    }

    .post-heading {
      .hr-line {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
        width: 100%;
        border-bottom: 1px solid rgba(129, 137, 156, 0.2);
      }
    }

    .event-btn {
      text-transform: uppercase;
      background-color: rgba(251, 131, 0, 0.16);
      color: #f4712b;
      font-size: 10px;
      margin-bottom: 5px;
      padding: 3px 8px;
      font-weight: 500;
      display: block;
      border-radius: 4px;
      border: none;
      outline: none;

      &.holiday-btn {
        background-color: rgba(31, 185, 219, 0.16);
        color: #1fb9db;
      }
    }

    .right-column {
      height: 85vh;
      overflow-y: hidden;
      overflow-x: hidden;
      padding-left: 8px;
      padding-right: 8px;
      @media screen and (max-width: 990px) {
        height: auto;
        overflow-y: unset;
        overflow-x: unset;
        padding: 0 8px;
        &.full-width {
          padding: unset;
        }
      }
    }

    .tabe-menu-row {
      display: block;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        &.tabe-menu {
          display: flex;

          li {
            background-color: #fff;
            padding: 10px 20px;
            border-radius: 10px 10px 0 0;
          }
        }
      }
    }

    .center-column {
      padding-left: 8px;
      padding-right: 8px;
      height: 85vh;
    }

    .tab-container-list {
      display: block;
      margin-top: 16px;
      height: 100%;

      .tab-box {
        height: 100%;
      }

      .tab-content {
        //box-shadow: 1px 1px 1px 0px rgba(44, 11, 74, .1) !important;
        background-color: #fff;
        border-radius: 0 0 10px 10px;
        padding: 5px 0;
        height: 100%;

        .tab-1,
        .tab-2 {
          display: flex;
          flex-direction: column;
          align-items: center;

          .accordion {
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
          }
        }
      }

      .nav-link {
        .tabIcon {
          width: unset !important;
          padding-right: 10px;
        }

        &:not(.active) {
          .tabIcon {
            opacity: 0.4;
          }
        }
      }

      .classes-list {
        margin-top: 10px;
        overflow-y: auto;
        height: 67.5vh;
        width: 100%;
        @media screen and (max-width: 767px) {
          height: 100%;
          overflow: hidden;
          .tabContent:nth-child(n + 4) {
            display: none;
          }
          &.-expanded {
            height: 100% !important;
          }
        }

        .tabContent:first-of-type {
          .tabPane {
            input[type="radio"] + label {
              &::before {
                display: unset !important;
              }

              &::after {
                display: unset !important;
              }
            }
          }
        }
      }

      .load-more {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: min-content;
        align-items: center;
        justify-content: center;
        background: #fff;
        opacity: 0.9;

        .ant-spin {
          padding: 0 15px;
          display: flex;
        }

        @media (max-width: 460px) {
          display: none;
        }
      }

      ul {
        border-bottom: none !important;
        //margin-bottom: -5px;
        li:first-of-type {
          border-right: 1px solid rgba(44, 11, 74, 0.15);
          border-top-right-radius: 10px;
        }

        li {
          flex: 1;

          a {
            background-color: #f6f6f6;
            border-radius: 10px 10px 0 0;
            font-weight: 500;
            font-size: 16px;
            text-transform: uppercase;
            color: rgba(0, 0, 0, 0.25) !important;
            background-repeat: no-repeat;
            background-position: 11px 14px;
            background-size: 13px;
            padding: 10px 10px 10px 10px !important;
            @media screen and (max-width: 460px) {
              font-size: 13px;
              background-position: 8px 12.9px;
              background-size: 12px;
              padding: 14px 10px 14px 31px;
              line-height: 1;
            }

            &:hover {
              background-color: #fff;
              color: #000 !important;
              border-color: transparent !important;
            }

            &.active {
              background-color: #fff !important;
              color: #000 !important;
              border-color: transparent !important;
            }
          }
        }

        //li:nth-of-type(2) {
        //a {
        //background-image: url(../../assets/image/calendar-previous.png);
        //}
        //}
      }

      .sticky {
        @media screen and (min-width: 460px) {
          position: sticky !important;
          z-index: 9999 !important;
          top: 0.1px;
        }
      }

      .card-btn:focus {
        outline: none;
      }

      .card-btn {
        position: absolute;
        top: 40px;
        right: 0;
        margin: 0;
        padding: 0;
        z-index: 2;
        width: 10px;
        margin-right: 10px;
        transform: rotate(90deg);
      }

      .elips-text {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        //width: 142px;
        overflow: hidden;
        @media screen and (max-width: 460px) {
          //width: 125px;
        }
      }

      .accordian-title-des {
        color: #524e59;
        font-size: 12px;
        display: block;
        font-weight: 500;
        line-height: 16px;
        text-align: left;
      }

      .accordian-title {
        font-size: 10px;
        display: block;
        margin-bottom: 0;
        font-weight: 500;
        text-align: left;
        line-height: 16px;
        color: rgba(82, 78, 89, 0.5);
        text-transform: uppercase;
      }

      .class-join-btn {
        height: 30px;
        font-size: 12px;
        width: 75px;
        background: #9351cd !important;
        border: none !important;
        outline: none !important;
        pointer-events: all;
        margin-top: 20%;
        @media screen and (max-width: 460px) {
          margin-top: 15px;
        }
      }

      .attended-btn {
        border: 1px solid #00af54 !important;
        background-color: transparent;
        outline: none;
        color: #00af54;
        font-size: 10px;
        text-transform: uppercase;
        font-weight: 400;
        //padding: 2px 8px 0;
        border-radius: 4px;
        @media screen and (max-width: 460px) {
          top: 8px;
          left: 44px;
        }
      }

      .repeat-btn {
        background-color: transparent;
        outline: none;
        font-size: 10px;
        text-transform: uppercase;
        font-weight: 400;
        border-radius: 4px;
        color: #faad14;
        border: 1px solid #faad14 !important;
        padding: 2px;
        @media screen and (max-width: 460px) {
          position: absolute;
          top: 8px;
          left: 44px;
        }
      }

      .gold-members {
        cursor: pointer;
        @media screen and (max-width: 460px) {
          padding: 15px !important;
        }
      }

      .missed-btn {
        border: 1px solid #e02f2f !important;
        background-color: transparent;
        outline: none;
        color: #e02f2f;
        font-size: 10px;
        text-transform: uppercase;
        font-weight: 400;
        padding: 2px 8px 0;
        border-radius: 4px;
        @media screen and (max-width: 460px) {
          top: 8px;
          left: 44px;
        }
      }

      .missed-btn:focus,
      .repeat-btn:focus,
      .attended-btn:focus {
        outline: none;
      }
    }

    .update-card-mobile-view {
      display: none;
      @media screen and (max-width: 767px) {
        display: block;
        margin-bottom: 16px;
      }
    }

    .update-card-desktop {
      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    .media-body {
      h6 {
        color: #000000;
        font-size: 14px;
        font-weight: 500;
        text-align: left;
      }

      .previous {
        flex-direction: row;
        display: flex;
        justify-content: space-between;
      }
    }

    .gray-btn {
      background-color: rgba(130, 138, 157, 0.2);
      border-radius: 4px;
      padding: 0 8px;
      height: 37px;
      color: #524e59;
      font-size: 14px;
      text-align: center;
      line-height: 16px;
      margin-top: 12px;
      border: none;
      display: none;
      width: 100%;
      display: none;
      font-weight: 500;
      @media screen and (max-width: 767px) {
        display: flex;
        width: 96%;
        margin: 8px 0;
        align-items: center;
        justify-content: center;
      }

      &.show {
        display: flex;
      }
    }

    .ant-spin {
      padding: 0 10px;
      display: flex;
    }
  }
}

.name-desc-width {
  width: 100%;
}
