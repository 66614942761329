@import "assets/stylesheet/variables.scss";

.student-profile-form-content {
  width: 1120px;
  min-height: 100px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 6px;
  box-shadow: 1px 1px 1px 0 rgba(44, 11, 74, 0.15);
  background-color: #ffffff;

  .profile-progress-bar {
    display: none;
  }

  @media (max-width: "1120px") {
    width: 100% !important;
  }

  @media (max-width: $max-resp-width) {
    width: 100% !important;
    .profile-progress-bar {
      display: block;
    }
  }

  .form-button-container {
    padding: 0 50px 20px 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid #dddddf;
    @media (max-width: $max-resp-width) {
      display: flex;
      justify-content: center !important;
      align-items: center !important;
      padding: 15px;
    }
    button {
      border-radius: 16px;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.11);
      font-family: Rubik;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.89px;
      text-align: center;
      width: 160px;
      height: 50px;
      margin-right: 20px;
      margin-top: 20px;
      color: white !important;
      &.next-btn {
        background-color: $magenta-color;
        color: #ffffff;
        @media (max-width: $max-resp-width) {
          width: 100%;
          margin: 0px;
        }
      }
      &.back-btn {
        border: solid 1px #524e59;
        color: #524e59;
        @media (max-width: $max-resp-width) {
          display: none;
        }
      }
    }
  }

  .student-profile-title {
    padding: 30px 56px;
    margin: 0px;
  }

  .profile-form-title-seperator {
    background-color: #dddddf;
    border: none;
    height: 1px;
    margin-bottom: 0px;
    @media (max-width: $max-resp-width) {
      display: none;
    }
  }

  .ant-select-selection {
    height: 46px;
    border-radius: 15px;
    background: rgba(240, 239, 241, 0.65);
    border: none;
    .ant-select-selection-selected-value {
      opacity: 0.9;
      font-family: Rubik;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.22px;
      text-align: left;
      color: #acacac;
      padding: 12px;
      padding-top: 13px;
    }
  }
  .student-profile-title-container {
    .ant-btn {
      display: none;
    }
    @media (max-width: $max-resp-width) {
      display: flex;
      padding: 15px 5px;

      .ant-btn {
        border: none;
        font-size: 26px;
        font-weight: bold;
        margin-top: -6px;
        display: block;
      }
      h1 {
        flex: 1;
      }
    }
  }

  .student-profile-title {
    font-family: Rubik;
    font-size: 32px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1.36px;
    text-align: left;
    color: #524e59;
    padding-bottom: 17px;
    @media (max-width: $max-resp-width) {
      font-size: 24px;
      padding: 0px;
      text-align: center;
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
}
.student-profile-container {
  height: 100%;
  min-height: 520px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  transition: none;

  .student-profile-sidebar {
    min-width: 300px;
    display: flex;
    align-items: flex-start;
    background-color: rgba(218, 218, 218, 0.1);
    justify-content: center;

    @media (max-width: $max-resp-width) {
      display: none;
    }

    .ant-steps-vertical {
      padding: 35px 36px;
    }
    .ant-steps-item-container {
      height: 80px;
      > .ant-steps-item-content {
        > .ant-steps-item-title {
          font-family: Rubik;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: -0.22px;
          color: #524e59;
        }
      }
    }
    .ant-steps-item-finish {
      > .ant-steps-item-container {
        > .ant-steps-item-tail {
          &::after {
            background: repeating-linear-gradient(
              to bottom,
              $magenta-color 0,
              $magenta-color 10px,
              transparent 10px,
              transparent 12px
            );
            width: 3px;
          }
        }
        > .ant-steps-item-content {
          > .ant-steps-item-title {
            color: #524e59;
            font-weight: 500;
          }
        }
      }
      .ant-steps-item-icon {
        > .ant-steps-icon {
          color: white;
          height: 10px !important;
          overflow: hidden;
          top: -11%;
        }
        background-color: $magenta-color;
        border: none;
      }
    }
    .ant-steps-item-process {
      .ant-steps-item-icon {
        background-color: $magenta-color;
        border: none;
        margin-right: 12px;
      }
    }
    .ant-steps-item-wait {
      .ant-steps-item-icon {
        background-color: rgba(0, 0, 0, 0.25);
        border-color: rgba(0, 0, 0, 0.25);
        width: 20px;
        height: 20px;
        margin-left: 7px;
        margin-top: 5px;
        border: none;
        > span {
          display: none;
        }
      }
      > .ant-steps-item-container {
        > .ant-steps-item-content {
          > .ant-steps-item-title {
            color: #b5b3b9;
          }
        }
      }
    }

    .ant-steps-item-process .ant-steps-item-icon {
      border: 3px solid $magenta-color;
      background-color: white;
      .ant-steps-icon {
        color: #9134d8;
        background: #9134d8;
        width: 18px;
        height: 18px;
        display: block;
        border-radius: 100px;
        margin: auto;
        top: 50%;
        margin-top: -9px;
      }
    }
  }
  .student-profile-main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;

    .form-steps-info {
      padding: 24px;
      padding-bottom: 0px;
      display: none;

      .step-number {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 10px;
        text-transform: uppercase;
        color: #979797;
      }
      .step-title {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
      }
      .step-description {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 0px;
      }
    }
    @media (max-width: $max-resp-width) {
      .form-steps-info {
        display: block;
      }
    }
  }
}
.form-button-container {
  clear: both;
}
