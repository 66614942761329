@import 'assets/stylesheet/variables.scss';
.form-field-grey, .textarea-grey {
  opacity: 0.6 !important;
  border-radius: 16px !important;
  background-color: #f0eff1 !important;
  padding: 25px 20px !important;
  border: none !important;
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.ant-select-dropdown {
  border-radius: 15px !important;
  overflow: hidden !important;
}
.ant-select-selection-selected-value {
  padding-top: 13px !important;
}

.student-profile-main {
  .form-fields {
    .form-field {
      .ant-select-arrow {
        margin-right: 8px;
      }
      .ant-input {
        font-weight: 500;
        font-size: 16px;
        color: black;
      }

      margin-top: 25px;

      .ant-form-item {
        margin: 0 !important;

        ::before {
          display: none;
          background: red;
        }
      }

      .ant-form-item-label {
        ::after {
          display: none;
        }

        label {
          margin-bottom: 0 !important;
        }
      }

      p {
        font-weight: 500;
        color: #979797;
        margin-bottom: 0px;
      }
    }

    .form-field {
      ::placeholder {
        opacity: 0.9;
        font-family: Rubik;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.22px;
        text-align: left;
        color: #999797;
      }
    }
    @media(max-width: $max-resp-width) {
      .ant-col {
        width: 100% !important;
      }
    }
  }
}

.student-profile-main {
  .form-fields {
    padding: 20px 40px;
    @media(max-width: $max-resp-width) {
      padding: 20px 22px;
      padding-top:0px;
    }
  }

  .ant-select-selection-selected-value {
    font-family: Rubik;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.22px;
    text-align: left;
    //font-weight: 400 !important;
    color: black !important;
    opacity: 0.6 !important;
    padding: 12px;
    padding-top: 13px;
  }
}
