// Will setup this using some variable for auto build
// but for now you must comment and un-comment accordingly for
// euro or freadom build.

// For freadom
// $theme-color: #3e2272;
// $theme-btn-color: #ff8a23;
// $theme-btn-color-hover: #d8751e;
// $theme-btn-color-disabled: #ffdcbd;
// $theme-gradient: linear-gradient(359deg, #552d83, #432870 64%, #361e5f);
// $magenta-color: #9134d8;
// $max-resp-width: "900px";

// For Rotary
// $theme-color: #064D96;
$theme-color: #0033A0;
$theme-btn-color: #408CCF;
$theme-btn-color-hover: #3d7eb7;
$theme-btn-color-disabled: #add1f1;
$theme-gradient: linear-gradient(359deg, #552d83, #432870 64%, #361e5f);
$magenta-color: #F7A800;
$max-resp-width: "900px";
$theme-background:#02246D;
$background-gradient: linear-gradient(360deg, #02246D, #02246D, #02246D);
$main-color:#0033A0;
$theme-border-radius:5px

// For Euro
// $theme-color: #2ba8e0;
// $theme-btn-color: $theme-color;
// $theme-btn-color-hover: #2693c5;
// $theme-btn-color-disabled: #2ba7e060;
// $theme-gradient: linear-gradient(359deg, #2be0ad, #2ba8e0);
