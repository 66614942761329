.results-screen {
  padding-top: calc(40px + 66px);
  width: 1145px;
  margin: auto;
  &.padding-top-0 {
    padding: 0px;
  }
  > h1 {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
  }

  .back-btn {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    cursor: pointer;
    opacity: 0.8;
  }

  .table-container {
    margin-top: 20px;
    height: 568px;
    overflow-y: auto;
    background: #ffffff;
    box-shadow: 1px 1px 1px rgba(44, 11, 74, 0.15);
    border-radius: 6px;
    padding: 12px;
    padding-top: 5px;

    table {
      border-collapse: separate;
      border-spacing: 0 10px;

      .ant-table-header-column {
        div {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 41px;
        }
      }
    }

    thead {
      th {
        &:nth-child(1) {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          padding-left: 50px;
        }

        &:nth-last-child(1) {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }

        height: 10px;
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 10px;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        border-bottom: 0px;
        text-transform: uppercase;
        color: #524e59;
      }
    }

    td {
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.222222px;
      padding: 30px 16px;
      color: #524e59;
      border-bottom: 1px solid #cccccc;
      border-top: 1px solid #cccccc;
      box-sizing: border-box;

      &:nth-child(1) {
        padding-left: 50px;
        border-left: 1px solid #cccccc;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      &:nth-last-child(1) {
        border-right: 1px solid #cccccc;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

    a {
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      text-transform: capitalize;
      color: #9351cd;
      border: 1px solid #9351cd;
      box-sizing: border-box;
      border-radius: 5.38365px;
      padding: 10px 18px;
      text-decoration: none;
    }

    .ant-table-placeholder {
      border-bottom: none;
    }
  }

  .report-pill {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      background: #f2bd1d;
      border-radius: 6px;
      text-align: center;
      padding: 5px 10px;
    }

    color: #ffffff;
  }
}
