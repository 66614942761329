.nametext {
  font-size: 17px !important;
}

.attendenceclass {
  opacity: 80%;
  margin-left: 2px;
}

svg {
  margin-left: 1px;
}

.edit-profile-btn {
  margin-top: 0px;
  // margin-left: 1.5rem;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  a {
    text-decoration: none;
    background-color: white;
    color: #9134d4;
    font-size: 12px;
    padding: 0px;
    border-radius: 8px;
    font-weight: 500;
  }
}

.user-card {
  .user-image {
    height: 65px !important;
    width: 65px !important;
    border-radius: 100px !important;
  }

  .child-detail {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 13px 10px;
    margin-top: 10px;
    margin-left: 5px;

    .edit-profile-btn {
      margin-top: 0px;
      display: flex;
      align-items: flex-end;
      cursor: pointer;

      a {
        text-decoration: none;
        background-color: white;
        color: #9134d4;
        font-size: 12px;
        padding: 0px;
        border-radius: 8px;
        margin-left: 2px;
      }

      // a {
      //   text-decoration: none;
      //   color: #9134d4;
      //   font-size: 12px;
      //   font-weight: 500;
      //   text-transform: uppercase;
      // }

      .editIcon {
        width: 17px;
        line-height: normal;
        padding: 0 5px;
        display: inline;
        // margin-left: 1rem;
      }
    }
  }

  .percent {
    position: relative;
    width: 100px;
    height: 20px;
    border-radius: 50%;
    background: #fff;
    z-index: 1000;

    svg {
      position: relative;
      width: 100px;
      height: 100px;
      z-index: 1000;
      // transform: rotateZ(-90deg);
      margin-left: -2px !important;
      margin-top: 1px;

      circle {
        width: 100%;
        height: 100%;
        fill: none;
        stroke: #fff;
        stroke-width: 3;
        stroke-linecap: round;
        transform: translate(15px, 5px);

        &:nth-child(1) {
          stroke: #c4c4c4;
          opacity: 0.5;
        }

        &:nth-child(2) {
          stroke: #00c891;
          stroke-dasharray: 250;
        }
      }
    }

    .number {
      position: absolute;
      top: -9px;
      left: -9px;
      width: 100%;
      height: 100%;
      // display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      flex-direction: column;
      // margin-left: 2rem;
      margin-top: 1rem;

      h2 {
        color: #524e59;
        font-weight: 700;
        font-size: 20px;
        transition: 0.5s;
        margin-bottom: unset;
      }

      span {
        font-size: 11px;
        color: #524e59;
        font-weight: 500;
      }
    }
  }

  .renew-row {
    background-color: rgba(216, 216, 216, 0.2);
    border-radius: 6px;
    padding: 3px 8px;
    color: #524e59;
    font-size: 14px;
    text-align: left;
    margin-top: 30px;
    font-weight: 600;

    span {
      font-weight: 400;
      margin-left: 3px;
    }

    .line-height-normal {
      line-height: normal;
      align-items: center;
      display: flex;
    }
  }

  .renew-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;

    span {
      font-weight: 400;
      margin-left: 3px;
      font-size: 14px;
      text-align: left;
      color: #524e59;
    }

    .renewBtn {
      background-color: #9351cd;
      border-radius: 4px;
      border: none;
      outline: none;
      font-size: 12px;
      font-weight: 400;
      height: 21px;
      color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      //@media screen and (max-width: 460px) {
      //  flex-basis: 62%;
      //}

      .rightArrowBtn {
        //width: 10px;
        line-height: normal;

        img {
          margin: 0 5px 0 14.5px;
          width: auto;
          @media screen and (max-width: 460px) {
            transform: translateY(-1px) !important;
          }
        }
      }
    }
  }
}
