.left-classes {
  margin-top: 8px;
  box-shadow: 1px 1px 1px 0px rgba(44, 11, 74, 0.1) !important;
  //padding: 21px;
  overflow: hidden;

  &.bg-danger {
    background-color: #f24e29 !important;
  }

  .classes-container {
    &.danger-text {
      span,
      p {
        color: #fff !important;
      }
    }

    .renew-text {
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: transform 400ms;

      &:hover {
        transform: translateX(4px);
        transition: transform 400ms;
      }

      p {
        font-family: Rubik;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        text-align: left;
        letter-spacing: 0.661157px;
        text-transform: uppercase;
        margin-top: 15px;
        margin-right: 12px;
      }
    }

    .message {
      align-items: flex-start;
      display: flex;
      flex-direction: column;

      span {
        display: block;
        font-family: Rubik;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #9351cd;

        .class-count {
          display: inline;
          font-weight: 600;
        }
      }
    }

    .classes-left-progress {
      display: flex;
      align-items: center;

      .progress {
        width: 100%;
        background-color: rgba(196, 196, 196, 0.7);
        height: 5px;
        margin: 20px 5px;
        border-radius: 51px;

        .filled {
          background-color: #9351cd;
          height: 5px;
          border-radius: 51px;
          transition-property: width;
          transition-duration: 500ms;
        }
      }

      p {
        margin: unset;
        font-family: Rubik;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: #9351cd;
        display: inline;

        .bold-letter {
          color: #9351cd;
          font-size: 15px;
          font-weight: 500;
        }
      }
    }
  }

  .renew-container {
    margin-left: 10px;
    display: flex;
    flex: 0.4;
    position: relative;

    .icon {
      cursor: pointer;
      position: absolute;
      z-index: 100;

      &.edit {
        bottom: 0;
        right: -12px;
      }

      &.sync {
        bottom: 1px;
        right: -4px;
      }
    }

    .circle-1 {
      background-color: rgba(210, 210, 210, 0.4);
      position: absolute;
      bottom: -27px;
      right: -29px;
      height: 75px;
      width: 75px;
      border-radius: 100px;
      z-index: 4;
    }

    .circle-2 {
      //background-color: rgba(255, 255, 255, 0.1);
      background-color: rgba(210, 210, 210, 0.25);
      position: absolute;
      bottom: -34px;
      right: -34px;
      height: 95px;
      width: 95px;
      border-radius: 100px;
      z-index: 3;
    }

    .circle-3 {
      background-color: rgba(210, 210, 210, 0.16);
      position: absolute;
      bottom: -45px;
      right: -43px;
      height: 120px;
      width: 120px;
      border-radius: 100px;
      z-index: 2;
    }
  }
}
