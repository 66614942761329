.imageIcon {
  width: 6% !important;
  margin-right: 1%; }

.worksheetlink {
  color: inherit;
  text-decoration: none; }

.worksheetbox {
  height: 90px;
  border: 2px solid gray;
  padding: 20px;
  margin: 20px;
  font-family: Rubik;
  width: 142px; }

.homebtn {
  margin-bottom: 3% !important; }

.ant-btn {
  margin-left: 5% !important; }

.updatedpara {
  margin-left: 12px !important; }

.updatedpara2 {
  margin-left: 21px !important; }

.ant-btn {
  background-color: #0033A0 !important;
  margin-top: 10px; }

.btn-link {
  text-decoration: none !important; }

.class-details {
  font-family: "Rubik";
  height: 90%;
  overflow-y: hidden; }
  .class-details .ant-modal {
    color: black; }
    @media screen and (max-width: 460px) {
      .class-details .ant-modal {
        width: 100% !important;
        max-width: unset; } }
  @media screen and (max-width: 460px) {
    .class-details {
      overflow-y: hidden;
      height: 100%;
      /*::-webkit-scrollbar {
      display: none;
    }*/ } }
  .class-details .ant-modal-content {
    overflow-y: auto;
    height: 72vh;
    background: white; }
    @media screen and (max-width: 460px) {
      .class-details .ant-modal-content {
        overflow-y: auto;
        height: 85vh;
        border-top-left-radius: 18px;
        border-top-right-radius: 18px; } }
  .class-details .ant-modal-close {
    display: inline-block; }
    .class-details .ant-modal-close .ant-modal-close-x {
      position: absolute;
      right: 9px;
      top: 5px; }
  .class-details .anticon-close {
    background: #ececec !important;
    border-radius: 100px !important;
    padding: 9px !important;
    display: inline; }
  .class-details .ant-modal-body {
    padding: 20px 25px !important;
    background-color: white !important; }
    @media screen and (max-width: 460px) {
      .class-details .ant-modal-body {
        padding: 0px !important; } }
  .class-details .attended-btn {
    border: 2px solid #00af54;
    background-color: transparent;
    outline: none;
    color: #00af54;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 400;
    padding: 2px 8px;
    border-radius: 5px;
    font-weight: 500; }
  .class-details h1,
  .class-details h2 {
    color: #524e59; }
  .class-details .modal-title {
    font-size: 28px; }
    @media screen and (max-width: 460px) {
      .class-details .modal-title {
        padding: 20px 0 20px 20px;
        font-size: 24px; } }
  .class-details .modal-data {
    height: 55vh;
    overflow: auto; }
    @media screen and (max-width: 460px) {
      .class-details .modal-data {
        padding: 0 20px !important; } }
  .class-details .mb-10 {
    margin-bottom: 10px; }
    @media screen and (max-width: 460px) {
      .class-details .mb-10 {
        margin-bottom: 0 !important; } }
  .class-details .detail-row {
    margin-bottom: 50px;
    border-top: 1px solid #ececec;
    padding-top: 30px; }
    .class-details .detail-row:nth-of-type(1) {
      padding-top: 0px;
      border: 0px; }
  @media screen and (max-width: 460px) {
    .class-details .subject-title {
      border-bottom: 1px solid #828a9d;
      padding: 0 !important; } }
  .class-details .subject-title .pill {
    margin-left: 15px; }
  .class-details .shadow-box {
    margin-bottom: 30px; }
  .class-details .h-500 {
    min-height: 500px; }
  .class-details .tab-content {
    color: #524e59;
    font-size: 16px; }
  .class-details .back-btn {
    color: #524e59;
    font-weight: 500;
    border: none;
    padding: none;
    margin: none;
    margin-left: -12px;
    font-size: 16px;
    width: auto;
    height: auto;
    margin-left: 3px; }
  .class-details .d-flex {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .class-details .d-flex.sb {
      justify-content: space-between; }
  .class-details .j-c-sb {
    justify-content: space-between; }
  .class-details .btn-outline-purple {
    border: 2px solid #F7A800;
    color: #F7A800;
    font-weight: bold;
    width: auto;
    height: auto;
    padding: 5px 5px;
    border-radius: 5px; }
  .class-details .homework-text {
    font-weight: bold;
    color: #219653;
    margin-left: 20px; }
  .class-details .class-timings {
    align-items: flex-start; }
    .class-details .class-timings svg {
      margin-right: 10px;
      margin-bottom: 4px; }
    .class-details .class-timings h3 {
      margin-right: 20px;
      color: #524e59 !important;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 0px; }
    .class-details .class-timings .timeSection {
      flex-wrap: wrap; }
      .class-details .class-timings .timeSection h3 {
        margin-bottom: 12px; }
  .class-details .seperator-20 {
    width: 100%;
    height: 20px; }
  .class-details .description .icon-text {
    margin-bottom: 10px; }
  .class-details .description h5 {
    font-size: 14px;
    margin-bottom: 0px;
    font-weight: 500; }
  .class-details .description p {
    margin-bottom: 30px;
    margin-left: 0.5rem; }
  .class-details .ant-modal-footer {
    display: none; }
  .class-details .ant-modal-header {
    display: inline; }
  .class-details .link-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start; }
    .class-details .link-cards .link-card {
      width: 240px;
      min-height: 200px;
      border-radius: 15px;
      overflow: hidden;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.11);
      margin-right: 20px;
      margin-top: 5px;
      margin-bottom: 15px;
      padding-bottom: 10px;
      cursor: pointer; }
      .class-details .link-cards .link-card h4 {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 3px; }
      .class-details .link-cards .link-card .card-image {
        width: 100%;
        height: 140px;
        object-fit: cover; }
      .class-details .link-cards .link-card .card-footer {
        padding: 10px 15px;
        background: none; }
        .class-details .link-cards .link-card .card-footer .author {
          font-weight: normal;
          color: #524e59;
          font-size: 12px;
          margin: 0px; }
          .class-details .link-cards .link-card .card-footer .author .name {
            color: #F7A800; }
