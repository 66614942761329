@import "~antd/dist/antd.css";

@font-face {
  font-family: "Rubik";
  src: url("./assets/Rubik/Rubik-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: "Rubik";
  src: url("./assets/Rubik/Rubik-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Rubik";
  src: url("./assets/Rubik/Rubik-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Rubik";
  src: url("./assets/Rubik/Rubik-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Rubik";
  src: url("./assets/Rubik/Rubik-Black.ttf") format("truetype");
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: "COCOGOOSELETTERPRESS";
  src: url("./assets/COCOGOOSELETTERPRESS.ttf") format("truetype");
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  color: #212121;
  background-color: #ececec !important;
  background: #ececec;
}

* {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.toast-body {
  font-family: Rubik;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #0033A0;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: #0033A0;
  -webkit-box-shadow: inset 0 0 6px #0033A0;
}
