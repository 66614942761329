.children-modal {
  font-family: "Rubik";
  height: 90%;
  overflow-y: hidden;
  margin: 10px 0; }
  @media screen and (max-width: 460px) {
    .children-modal {
      overflow-y: hidden;
      height: 100%;
      /*::-webkit-scrollbar {
      display: none;
    }*/ } }
  .children-modal .ant-modal {
    top: 160px; }
    @media screen and (max-width: 460px) {
      .children-modal .ant-modal {
        width: 100% !important;
        max-width: unset;
        top: 100px; } }
  .children-modal .row {
    margin-top: 10px;
    margin-bottom: 10px; }
  .children-modal .d-flex {
    display: flex;
    flex-direction: row; }
    .children-modal .d-flex.reverse {
      flex-direction: column; }
  .children-modal .listing-row-profile-pic {
    height: 60px;
    width: 60px;
    border-radius: 30px;
    background: #979797;
    display: inline-block;
    margin: 0;
    overflow: hidden;
    vertical-align: middle;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }
    .children-modal .listing-row-profile-pic img {
      height: 100%; }
  .children-modal .j-c-c {
    justify-content: center; }
  .children-modal .j-c-sb {
    justify-content: space-between; }
  .children-modal .j-c-se {
    justify-content: space-evenly; }
  .children-modal .j-c-fs {
    justify-content: flex-start; }
  .children-modal .a-i-fs {
    align-items: flex-start; }
  .children-modal .ant-modal-header {
    display: block;
    background-color: white;
    padding: 25px 15px;
    border: none; }
    @media screen and (max-width: 460px) {
      .children-modal .ant-modal-header {
        border-bottom: 1px solid #828a9d; } }
    .children-modal .ant-modal-header .ant-modal-title {
      color: #524e59;
      font-size: 28px; }
      @media screen and (max-width: 460px) {
        .children-modal .ant-modal-header .ant-modal-title {
          font-size: 24px; } }
  .children-modal .ant-modal-content {
    background-color: white !important;
    color: #7d7a83;
    padding-bottom: 7px;
    overflow-y: auto;
    height: 62vh; }
    @media screen and (max-width: 460px) {
      .children-modal .ant-modal-content {
        overflow-y: auto;
        height: 85vh;
        border-top-left-radius: 18px;
        border-top-right-radius: 18px; } }
    .children-modal .ant-modal-content .ant-modal-body {
      padding: 0px 10px 18px 10px !important;
      position: relative;
      height: 48vh;
      overflow: auto; }
      @media screen and (max-width: 460px) {
        .children-modal .ant-modal-content .ant-modal-body {
          padding: 10px 0 25px 0 !important;
          height: 55vh; } }
      .children-modal .ant-modal-content .ant-modal-body .children-cards {
        flex-wrap: wrap;
        padding-bottom: 10px; }
        .children-modal .ant-modal-content .ant-modal-body .children-cards .no-data-label {
          font-size: 18px;
          padding: 0 0 0 9px; }
        .children-modal .ant-modal-content .ant-modal-body .children-cards .children-card {
          flex-basis: 32.2%;
          padding: 15px 20px;
          border-radius: 8px;
          border: 1px solid rgba(151, 151, 151, 0.3);
          margin: 5px; }
          @media screen and (max-width: 460px) {
            .children-modal .ant-modal-content .ant-modal-body .children-cards .children-card {
              flex-basis: 100%;
              margin: 7.5px 15px;
              padding: 15px 25px; } }
          @media screen and (max-width: 460px) {
            .children-modal .ant-modal-content .ant-modal-body .children-cards .children-card .batchmate-content {
              display: flex;
              flex-direction: row; } }
          .children-modal .ant-modal-content .ant-modal-body .children-cards .children-card .batchmate-content .profile {
            display: flex;
            flex-direction: row;
            justify-content: space-between; }
            .children-modal .ant-modal-content .ant-modal-body .children-cards .children-card .batchmate-content .profile .wow-pic img {
              width: 60px;
              object-fit: contain;
              height: 50px; }
              @media screen and (max-width: 460px) {
                .children-modal .ant-modal-content .ant-modal-body .children-cards .children-card .batchmate-content .profile .wow-pic img {
                  width: 50px;
                  height: 40px; } }
          .children-modal .ant-modal-content .ant-modal-body .children-cards .children-card .batchmate-content .batchmate-detail {
            display: flex;
            width: 100%;
            flex-direction: column;
            margin-top: 15px; }
            @media screen and (max-width: 460px) {
              .children-modal .ant-modal-content .ant-modal-body .children-cards .children-card .batchmate-content .batchmate-detail {
                margin-left: 10px;
                margin-top: unset; } }
            .children-modal .ant-modal-content .ant-modal-body .children-cards .children-card .batchmate-content .batchmate-detail h2 {
              color: #524e59;
              font-size: 20px;
              margin-bottom: 12px; }
            .children-modal .ant-modal-content .ant-modal-body .children-cards .children-card .batchmate-content .batchmate-detail .stats {
              display: flex;
              justify-content: space-between; }
              .children-modal .ant-modal-content .ant-modal-body .children-cards .children-card .batchmate-content .batchmate-detail .stats .purple-text {
                color: #F7A800;
                font-weight: 500;
                font-size: 18px;
                margin-bottom: 0; }
                .children-modal .ant-modal-content .ant-modal-body .children-cards .children-card .batchmate-content .batchmate-detail .stats .purple-text ~ p {
                  font-weight: 400;
                  margin-bottom: 0; }
  .children-modal .ant-modal-footer {
    background-color: white;
    border: none;
    text-align: left;
    padding: 40px;
    padding-bottom: 60px;
    display: none; }
  .children-modal .ant-modal-close {
    display: inline; }
    .children-modal .ant-modal-close .anticon {
      padding: 9px;
      background: #ececec;
      border-radius: 100px; }
  .children-modal .ant-modal-close-x {
    position: absolute;
    right: 2px;
    top: 5px; }
    @media screen and (max-width: 460px) {
      .children-modal .ant-modal-close-x {
        right: 3px; } }
