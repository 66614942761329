.empty-announcements {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    margin-bottom: 32px;
    @media screen and (max-width: 1100px) {
      margin-bottom: 12px;
    }
  }

  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    margin-bottom: 16px;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
    margin-bottom: 32px;
    text-align: center;
  }
  .ant-btn {
    background: #9134d8;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.11);
    border-radius: 8px;
    width: 242px;
    height: 50px;

    span {
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      /* identical to box height */

      text-align: center;
      letter-spacing: 0.888889px;
      text-transform: uppercase;

      color: #ffffff;
    }
  }
}