.reports-card {
  h4 {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;

    /* Text Grey */
    text-align: left;
    color: #524e59;
  }
  h6 {
    text-align: left;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    width: 132px;
    /* Text Grey */

    color: #524e59;
    margin-bottom: 16px;
    mix-blend-mode: normal;
    opacity: 0.7;
  }
  .ant-btn {
    background: #9351cd;
    border-radius: 4px;
    border-radius: 5px !important;
    margin: 0px !important ;
    span {
      font-family: Rubik;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      letter-spacing: 0.661157px;
      text-transform: uppercase;
      /* White */

      color: #ffffff;
    }
  }
  .card-icon {
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
  .teacher-section {
    position: relative;
    padding: 20px 25px !important;
  }
  .osahan-user-media-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
