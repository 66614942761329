@import "node_modules/react-image-gallery/styles/scss/image-gallery.scss";
@import 'assets/stylesheet/variables.scss';

.component-media-viewer {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  // padding-top: 10px;
  .ant-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .audio-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .image-gallery {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      // max-width: 80vw;
      max-height: 70vh;
      object-fit: contain;
    }
    .image-gallery-content{
      width: 100%;
    }
  }
  .close-icon {
    position: absolute;
    right: 16px;
    top: 30px;
    cursor: pointer;
    font-size: 30px;
  }
/* Define mobile breakpoint */
$mobile-breakpoint: 768px; /* Adjust as needed */

/* Styles for close icon */
.close-icon {
  position: absolute;
  right: 16px;
  top: 30px;
  cursor: pointer;
  font-size: 30px;
}

/* Styles for timer */
// .timer {
//   position: absolute;
//   left: 5%;
//   top: 10px;
//   max-width: 80vw;
//   z-index: 10000;
// }

.timer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 5px;
}

/* Media query for mobile views */
@media (max-width: $mobile-breakpoint) {
  /* Adjust styles for close icon */
  // .close-icon {
  //   right: 10px; /* Shift to the left */
  //   top: 20px; /* Adjust vertical position */
  //   font-size: 20px; /* Make the font size smaller */
  // }

  /* Adjust styles for timer */
  .timer {
    left: 10px; /* Shift to the left */
    top: 10px; /* Adjust vertical position */
    // max-width: 70vw; /* Reduce max-width for smaller screens */
  }
}


  #pdf-view {
    max-height: 95vh;
    max-width: 100vw;
  }
}

@media (max-width: 1023px) {
  .component-media-viewer {
    padding-top: 0px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // flex-direction: column;
  }
  #pdf-view {
    overflow-y: auto;
    margin-top: 48px;
    min-height: 90vh;
    min-width: 90vw;
    .pdf-outerdiv {
      // transform: scale(1) !important;
      .pdf-viewer {
        border-width: 0px;
        box-shadow: none;
      }
    }
  }
}

.touchPDF,
.touchPDF *,
.touchPDF *:before,
.touchPDF *:after {
  -webkit-box-sizing: content-box;
     -moz-box-sizing: content-box;
          box-sizing: content-box;
}


.touchPDF {
  // overflow-y: scroll;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: default;
  font-family: arial, verdana, sans-serif;
  font-size: 16px;
  &::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
  }
  .pdf-outerdiv {
    position: relative;
    -webkit-transform-origin: 0 0 0;
    -ms-transform-origin: 0 0 0;
    transform-origin: 0 0 0;
    // div {
    //   position: absolute;
    //   top : 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    // }
    .pdf-toolbar {
      height: 0;
      padding: 7px 0;
      text-align: center;
      margin-top: 10px;

      // position: absolute;
      // display: flex;
      // justify-content: center;
      .pdf-button {
        display: inline-block;
        margin-right: 3%;
        .pdf-page-count {
          display: inline-block;
          min-width: 80px;
          font-size: 1.5rem;
          font-weight: 500;
          text-align: center;
        }
      }
    }
    .pdf-viewer {
      border: 0px solid #404040;
      box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.4);
      z-index: 2000;
      background-color: #ffffff;
      overflow: hidden;
      border-width: 1px;
      .pdf-drag {
        position: absolute;
        top: 0;
        left: 0;
        canvas {
          visibility: hidden;
        }
      }
      .pdf-annotations {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2500;
        .annotLink {
          a {
            font-size: 1em;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            background: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAA LAAAAAABAAEAAAIBRAA7") repeat scroll 0 0 rgba(0, 0, 0, 0);
            &:hover {
              background: none repeat scroll 0 0 #ff0;
              box-shadow: 0 2px 10px #ff0;
              opacity: 0.2;
            }
          }
        }
      }
      .pdf-loading {
        position: absolute;
        top: 60px;
        left: 0px;
        width: 100%;
        z-index: 3000;
        text-align: center;
        vertical-align: middle;
        color: #CCCCCC;
      }
    }
  }
}

.select_voice{
max-width: fit-content;
height: 2.5rem;

.ant-select-selection{
  width:100%;
  border-radius: 5px !important;
  height: 100%;

  .ant-select-selection__rendered{
    height: 100%;
    display: flex;
    align-items: center;
  }
}
}

.select_voice_option{
  &:hover{
    background-color: #7927c2 !important;
    color: white;
  }
}
.select_voice_option.ant-select-dropdown-menu-item-selected{

    background-color: #7927c2 !important;
    color: white !important;

}

.ant-select-dropdown.custom-dropdown{
  width: fit-content !important;
}

.pdf-container {
  height: 90%;
  display: flex;
  // justify-content: center;
  align-items: center;
  flex-direction: column;
  // padding: 0.5rem 0;
  width: 100%;
  overflow: hidden;
}
.pdf-page-content {
  margin: 10px 20px ;
  width: fit-content;
  min-width: 500px;
  max-width: 90%;
  height: 90%;
  // max-height: 85vh;
  // width: 90%;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  overflow: auto;

  transition: 0.5s ease-in-out;
  // opacity: 1;
  // transform:translateX(0);
  // transform: rotateY(0);
  animation: 0.75s slide-right;
}

@keyframes slide-right {
  from {
    transform:translateX(-150%);
  }
  to {
    transform:translateX(0);
  }
}

.pdf-page-content.fade_out{
  opacity: 0;
}

.pdf-page-content.left {
  // opacity: 0;
  transform: rotateY(90deg);
  // transform: translateX(-150%);
}
.pdf-page-content.right {
  // animation: 2s slide-right;
  opacity: 0;
  // transform: rotateY(90deg);
  transform: translateX(150%);
}

.pdf-toolbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 500px;
  align-items: center;
  position: absolute;
  bottom: 5px;
  gap: 7px;
}
.button-container {
  display: flex;
  gap: 8px;
  align-items: center;
}

.pdf_control_btn{

  border-radius: 50%;
      width: 40px;
      height: 40px;
      padding: 0px;
      font-size: 25.92px;
      line-height: 48.65px;
      background-color: $theme-color;
      color: white;
      border: 4px solid $theme-color;
      font-weight: bolder;
      display: flex;
      align-items: center;
      justify-content: center;
      filter: drop-shadow(2px 2px 2px $theme-color);
      z-index: 10;

      &:disabled {
          color: #fff !important;
          opacity: 0.5;
        }
}

.button-container > button{
}
.header-div{
      background: rgb(255, 210, 63);
    width: 100%;
    height: 9.5%;
    top: 0px;
    left: 45px;
    margin-top: 0px;
    z-index: 1000000;
    margin-left: 45px;
    margin-right: 45px;
}

.react-pdf__Document{
    width: 100% !important;
    filter: drop-shadow(0px 0px 4px rgb(243, 216, 129));
  }



.demoPage {
  width: 100% !important;
  height: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.demopage .react-pdf__Page{
  width:100% !important;
  height: 100% !important;
}
// .react-pdf__Page__canvas{
//   height: 450px !important;
// }

// .react-pdf__Page__textContent{
//   height: 450px !important;
// }

.pdf_page{
  width: 100%;
    display: flex;
    align-items: center;
    padding: 0 15px;
    height: 92%;
    justify-content: space-between;

}

#epub-viewer{
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  min-width: 800px;


}

#epub-render{

  .epub-container{
  width: 100% !important;
  height: 100% !important;
  }
  .epub-view{
    width: 100% !important;
    // height: auto !important;
    // overflow: auto !important;

    .epub-viewer-next,
.epub-viewer-previous {
  display: none !important;
}
  }
  iframe {
  width: 100% !important;
  height: 100% !important;
  overflow: scroll !important;

}
body{
  width: 100% !important;
  height: 100% !important;
}
button{
  border-radius: 50%;
      width: 40px;
      height: 40px;
      padding: 0px !important;
      font-size: 38.92px !important;
      line-height: 48.65px;
      background-color: rgb(145, 52, 216) !important;
      color: white !important;
      border: 4px solid rgb(145, 52, 216) !important;
      font-weight: bolder !important;
      display: flex;
      align-items: center;
      justify-content: center;
      filter: drop-shadow(2px 2px 2px rgb(145,52,216));
      z-index: 100;

      &:disabled {
          color: #fff !important;
          opacity: 0.5;
        }
}
}

.highlight {
  background-color: yellow;
  transition: background-color 0.2s;
}

.epub_container{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 0 10px;
}

/* src/EpubReader.css */

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.reader {
  width: 80%;
  height: 80vh;
  border: 1px solid #ccc;
  overflow: hidden;
  margin-bottom: 20px;
}

.controls {
  display: flex;
  gap: 10px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.epub-container{
  width: fit-content !important;
  min-width: 500px !important;
  max-width: 90% !important;
  overflow: auto !important;
}

.finish_btn{
  position: absolute !important;
  bottom: 5px;
  right: 15px;
  filter: drop-shadow(0px 0px 1px rgb(145,52,216));
}

.quiz_container{
  position: absolute;
  inset: 0;
  background-color: white;
}

.turning {
  transform: rotateY(90deg);
}

.button-container .finish_btn_small{
  margin-top: 0 !important;
}



.pdf-book-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin: 20px;
  height: 80%;
  overflow-y: auto;
  overflow-x:hidden;
  width: 100%;
  max-width: 900px;
}

// .pdf-document {
//   display: none !important; /* Hide the original document rendering */
// }

// .demoPage {
//   width: 100%;
//   height: 100%;
//   display: flex !important;
//   justify-content: center;
//   align-items: center;
// }

.demoPage canvas {
  // width: 100% !important;
  // height: 100% !important;
  object-fit: contain;
}

.flipbook{
  width: 100% !important;
  height: 100% !important;
  // min-width: none !important;
  // max-width: none !important;
  // min-height: none !important;
  max-height: 100% !important;
}

.modal-90w{
    position:fixed;
    top: 12%;
    bottom: 10%;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    overflow-x: hidden;
    min-width: 0;
    min-height: 0;
    width: fit-content;
    height: fit-content;
    object-fit: contain;
}

.pdf_main_container{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.stf__block{
  width: 100% !important;
}

// .pdf_page{
//   //  filter: drop-shadow(2px 2px 2px black);
// }

.react-pdf__Page canvas{
  width: 100% !important;
  min-height: 560px;
  min-width: 600px;
  filter: drop-shadow(0 0 2px rgb(243, 216, 129));
  user-select: all !important;
  // height: 100% !important;
  // object-fit: contain;
}

.epub-controls{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 40px;
}

@media (max-width:1040px) {
  .modal-90w{
    top:5%;
    position: relative;
    max-height: 85%;
  }

}

@media (max-width:650px) {
  #header-div{
    height: 6% !important;
  }
  .pdf-page-content{
    min-width: 0;
    width: 100%;
    margin: 0
  }
  .react-pdf__Document{
    width: 100% !important;
  }
  .pdf_control_btn{
    width: 30px;
    height: 30px;
  }

  .modal-90w{
    overflow-x: auto !important;
  }
.stf__parent{
  min-width: 0 !important;
}
  .react-pdf__Page canvas{
  width: 100% !important;
  min-height: 0px;
  min-width: 0px !important;
  filter: drop-shadow(0 0 2px rgb(145,52,216));
  // height: 100% !important;
  // object-fit: contain;
}

.pdf_page{
  // width: 100% !important;
  padding:0 5px;
}

#epub-render{
  width: 100% !important;
}

.epub-controls{
  gap: 10px;
  justify-content: space-between;
}

.ant-modal{
  margin: 0 !important;
}

}