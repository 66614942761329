.testbtn{
  margin-left:-2% !important;
  margin-top:2% !important
}

.testdatedata{
  margin-left:21% !important;
  margin-top:2% !important;
}

.test-row {
  margin: auto 0;
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-bottom: 15px !important;
  @media screen and (max-width: 460px) {
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-bottom: 15px !important;
  }

  .test-date {
    display: flex;
    align-items: center;
    font-size: 10px !important;
    color: #524e59 !important;
    @media screen and (max-width: 990px) {
      flex-direction: column;
    }
    .dot {
      width: 4px;
      height: 4px;
      border-radius: 2px;
      background-color: #524e59;
      margin: 0 4px;

      &.red {
        background-color: #f24e29;
      }

      &.light {
        opacity: 0.5;
      }
    }
  }

  .test-heading {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000 !important;
  }

  .attend-btn {
    background-color: #9134d8;
    border-radius: 4px;
    // height: 28px;
    // width: 90px;
    height: 30px;
    width: 179px;
    padding: 8px;
    border: none;
    text-transform: uppercase;
    text-align: center;
    outline: none;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.66px;
    color: #fff;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 767px) {
      display: block;
      margin-bottom: -3px;
    }
  }
}
.ht-lmt {
  overflow: hidden;
  height: 215px !important;
  @media screen and (min-width: 768px) and (max-width: 990px) {
    height: 150px !important;
  }
}
.test-row:last-of-type {
  .hr-line {
    display: none;
  }

  @media screen and (max-width: 460px) {
    margin-bottom: unset !important;
  }
}
