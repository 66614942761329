@import './variables.scss';

.primary-btn {
  background-color: $theme-btn-color !important;
  color: #fff;
  font-size: 16px;
  border: none;
  font-weight: 500;
  border-radius: 6px;
}

.primary-btn:focus {
  background-color: $theme-btn-color;
}

.primary-btn:hover {
  background-color: $theme-btn-color-hover;
}


.outline-btn {
  background-color: transparent;
  color: $theme-btn-color;
  font-size: 16px;
  border: 1px solid $theme-btn-color;
  font-weight: 500;
  border-radius: 6px;
}

.outline-btn:focus {
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
}

.outline-btn:hover {
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
}

// display
.flex {
  display: flex;
}

//direction
.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

//wrap
.flex-wrap {
  flex-wrap: wrap;
}

//align-items
.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: flex-end;
}

// justify-content
.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.text-white {
  color: #fff;
}

.text-12 {
  font-size: 12px;
}

.ant-modal-mask {
  background: rgba(0, 0, 0, 0.92);
}

.icon-text {
  display: flex;
  align-items: center;

  svg {
    margin-right: 3px;
  }
}

.custom-dropdown {
  background-color: #fff !important;
  border-radius: 0 !important;
  border: none;
  padding: 0;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.06) !important;

  &:before {
    display: none;
    content: "";
    position: absolute;
    bottom: -20px;
    left: 0;
    background: #f0eff1;
    width: 100%;
    height: 20px;
  }


  &.ant-select-dropdown-placement-bottomLeft {
    border-radius: 0 0 20px 20px !important;

    &:before {
      top: -20px;
      bottom: auto;
    }
  }

  .ant-select-dropdown-menu {
    overflow-x: hidden;
  }


  .ant-select-dropdown-menu-item {
    font-size: 16px;
    font-weight: 500;
    color: #524e59;
    padding: 10px 16px;

    &:hover,
    &:active,
    &:focus,
    &-active {
      background-color: #4C1A73;
      color: #fff;
    }
  }
}
