@import "assets/stylesheet/variables.scss";

$grey-color-light: #a8a7ac;

.student-profile-form {
  padding-top: 100px;
  min-height: 100vh;
  background-color: #f6f6f6;

  @media (max-width: $max-resp-width) {
    &.zero-padding {
      padding-top: 0 !important;
    }
  }

  .student-profile-form-content {
    width: 1120px;
    min-height: 85vh;
    background-color: red;
    margin-left: auto;
    margin-right: auto;
    border-radius: 6px;
    box-shadow: 1px 1px 1px 0 rgba(44, 11, 74, 0.15);
    background-color: #ffffff;

    .form-complete {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      @media (max-width: $max-resp-width) {
        flex-direction: column;
        &.full-height {
          display: flex;
          height: 100vh;
        }
      }

      button {
        border-radius: 16px;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.11);
        font-family: Rubik;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.89px;
        text-align: center;
        width: 160px;
        height: 45px;
        margin-right: 20px;
        margin-top: 20px;

        &.home-btn {
          background-color: $magenta-color;
          color: #ffffff;

          &.disabled {
            background-color: $grey-color-light;
          }

          span {
            margin-right: unset !important;
          }
        }

        &.edit-btn {
          border: solid 1px #524e59;
          color: white;

          span {
            margin-right: unset !important;
          }
        }
      }

      > div {
        width: 50%;
        height: 100%;
        padding: 150px 90px;
        overflow: hidden;

        @media (max-width: $max-resp-width) {
          width: 100%;
          padding: 20px;

          &.form-complete-text {
            order: 2;
            display: flex;
            flex-direction: column;
            justify-content: center;

            span {
              margin-right: 10px;
            }

            br {
              display: none;
            }
          }
          &.form-complete-image {
            order: 1;
          }
        }

        h1 {
          font-family: Rubik;
          font-size: 48px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -1.96px;
          text-align: left;
          color: #524e59;
          @media (max-width: $max-resp-width) {
            font-size: 32px;
            margin: 0px;
            padding: 0px;
            text-align: center;
            width: 100%;
            height: auto;
            margin-top: 10px;
            margin-bottom: 10px;
          }
        }

        p {
          opacity: 0.6;
          font-family: Rubik;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.22px;
          text-align: left;
          color: #524e59;
          @media (max-width: $max-resp-width) {
            text-align: center;
            width: 100%;
          }
        }

        img {
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      }

      @media (max-width: $max-resp-width) {
        .form-complete-buttons {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .edit-btn {
          margin: 10px;
        }
        .home-btn {
          margin: 10px;
        }
      }
    }
  }
}
