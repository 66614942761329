@import 'assets/stylesheet/variables.scss';

.class-schedule-form-fields {
  height: 60vh;
  overflow-y: auto;

  @media(max-width: $max-resp-width) {
    height: 100%;
    overflow-y: unset;
  }

  .load-more {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: min-content;
    align-items: center;
    justify-content: center;
    background: #FFF;
    opacity: 0.9;

    .ant-spin {
      padding: 0 15px;
      display: flex;
    }

    @media(max-width: $max-resp-width) {
      display: none;
    }
  }

  .load-more-btn {
    display: none;
    @media(max-width: $max-resp-width) {
      display: flex;
      font-size: 15px;
      justify-content: center;
      cursor: pointer;

      .ant-spin {
        padding: 0 15px;
        display: flex;
      }
    }
  }

  .flat-btn {
    color: $magenta-color;
    font-weight: 500;
    border: none;
    font-size: 18px;
    outline: none;
    margin-top: 10px;
    padding: 0px;

    &.add-another-btn {
      margin-top: 55px;
    }
  }

  .form-heading {
    //height: 10px;
    margin: 0 0 8px 0 !important;
    font-family: Rubik;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.83;
    letter-spacing: normal;
    text-align: left;
    color: #979797;
  }
}
