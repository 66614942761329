@import 'assets/stylesheet/variables.scss';

.batch-selector-container {
  .batch-selector {

    .ant-select-selection {
      height: 52px;
      border-radius: 6px;

      &:focus,
      &:hover {
        box-shadow: none;
        border: 1px solid #9351CD;
      }

      .ant-select-selection__rendered {
        margin-left: unset;

        .ant-select-selection-selected-value {
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: -0.22px;
          color: #000;
          font-family: Rubik;
          line-height: 19px;
          text-align: center;
          padding: 16px 0 16px 18px !important;
        }
      }


      .ant-select-arrow {

        svg {
          path {
            fill: #525860;
          }
        }
      }
    }
  }
}
