@import 'assets/stylesheet/variables.scss';

.schedule-card-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

  &.disable {
    .card {
      cursor: not-allowed !important;
    }

    cursor: not-allowed !important;
  }

  .card {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex-basis: 31%;
    //height: auto;
    min-height: 183px;
    //min-height: 142px;
    margin: 8px 16px 8px 0;
    padding: 24px 6.5px 0 32px;
    border-radius: 16px;
    border: solid 2px rgba(82, 78, 89, 0.1);
    cursor: pointer;
    background: #ffffff url("../../../assets/image/calendar.svg") no-repeat 133px 88px;

    @media(max-width: $max-resp-width) {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      min-height: 273px;
      margin: 8px;
      border-radius: 16px;
      border: solid 2px rgba(82, 78, 89, 0.1);
      cursor: pointer;
      padding: 16px;
      padding-top: 26px;
      flex-basis: 45%;
      background: #ffffff url("../../../assets/image/calendar.svg") no-repeat 53px 177px;
    }

  }

  .card.selected {
    border: solid 3px #9134d8;

  }

  .heading {
    flex: 1;
    height: 17px;
    font-family: Rubik;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.19px;
    text-align: left;
    color: #524e59;
    position: sticky;
    top: 0;
    position: -webkit-sticky;
    z-index: 50;
    background: #fff;
  }

  .content {
    //position: absolute;

  }

  .image-container {
    //flex: 1;
    //background-color: #e0a800;
    display: flex;
    display: none;
    justify-content: flex-end;
  }

  img {
    position: absolute;
    transform: translateY(-17px) translateX(0);
  }

  .time-slots-section {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    overflow-y: auto;

    .time-slot {
      flex: 1;
      height: 14px;
      opacity: 0.6;
      padding: 4px 0;
      font-family: Rubik;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.19px;
      text-align: left;
      color: #524e59;

      .day-name {
        font-weight: bold;
        display: inline;
      }

      .day-name::after {
        content: '';
        padding: 0 3px 0 0;
      }
    }
  }
}
